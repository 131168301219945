import { useEffect, useState } from "react";
import Map from "./components/Map";
import Pages from "./pages/Pages";


function App() {
  
    return (
      <div className="App">
        <Pages/>
      </div>
    );
}

export default App;
